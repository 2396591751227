export default () => ({
    isOpen: false,

    init() {
        this.$el.addEventListener(
            'toggle',
            (event) => (this.isOpen = event.newState === 'open')
        )
    },
})
