import { dialog } from '@/lib/dialog.js'
import { translate } from '@/lib/helpers.js'

export default (consent) => ({
    consent: consent,

    showVideo() {
        const html = this.$refs.template?.content?.cloneNode(true)
        const root = this.$root

        root.innerHTML = ''
        root.append(html)
    },

    handleAccept() {
        if (window.Cookiebot.consent[this.consent] === true) {
            this.showVideo()
        }

        window.removeEventListener(
            'CookiebotOnAccept',
            this.handleAccept.bind(this),
            false
        )
    },

    handleClick() {
        if (
            this.consent &&
            window.Cookiebot &&
            window.Cookiebot?.consent?.[this.consent] !== true
        ) {
            const dialogTitle = translate('updatecookiesettings')
            const dialogText = translate(
                `acceptcookiestoviewvideo.${this.consent}`
            )
            const dialogAccept = translate('accept')
            const dialogCancel = translate('cancel')

            dialog(dialogTitle, dialogText, dialogAccept, dialogCancel)
                .then(() => {
                    const { preferences, statistics, marketing } = {
                        ...window.Cookiebot?.consent,
                        [this.consent]: true,
                    }

                    window.addEventListener(
                        'CookiebotOnAccept',
                        this.handleAccept.bind(this),
                        false
                    )

                    window.Cookiebot.submitCustomConsent(
                        preferences,
                        statistics,
                        marketing,
                        true
                    )
                })
                .catch((error) => error && console.log(error))
        } else {
            this.showVideo()
        }
    },
})
