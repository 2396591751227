import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { createFocusTrap } from 'focus-trap'

function c(tag = '', attributes = {}, children) {
    const el = document.createElement(tag)

    for (const attribute in attributes) {
        el.setAttribute(attribute, attributes[attribute])
    }

    el.append(...[children].flat(1).filter((child) => child))

    return el
}

export function dialog(
    title = '',
    text = '',
    acceptLabel = '',
    declineLabel = ''
) {
    return new Promise((resolve, reject) => {
        const backdrop = c('button', {
            tabindex: '-1',
            type: 'button',
            title: declineLabel,
            class: 'absolute inset-0 bg-black/50 opacity-0 transition-opacity',
        })

        const declineButton = c(
            'button',
            {
                type: 'button',
                title: declineLabel,
                class: 'block w-full rounded-md p-3 text-center',
            },
            declineLabel
        )

        const acceptButton = c(
            'button',
            {
                type: 'button',
                title: acceptLabel,
                class: 'block w-full rounded-md p-3 text-center',
            },
            acceptLabel
        )

        const modal = c(
            'div',
            {
                class: 'relative max-h-full w-full overflow-auto rounded-lg bg-white shadow-2xl sm:max-w-sm opacity-0 transition-all translate-y-8',
            },
            [
                c(
                    'div',
                    {
                        class: 'space-y-2 p-8 text-center',
                    },
                    [
                        c(
                            'h2',
                            {
                                class: 'font-serif text-3xl',
                            },
                            title
                        ),
                        text ? c('p', {}, text) : null,
                    ]
                ),
                c(
                    'div',
                    {
                        class: 'flex divide-x border-t',
                    },
                    [
                        c(
                            'div',
                            {
                                class: 'flex-1 p-1',
                            },
                            declineButton
                        ),
                        c(
                            'div',
                            {
                                class: 'flex-1 p-1',
                            },
                            acceptButton
                        ),
                    ]
                ),
            ]
        )

        const el = c(
            'div',
            {
                class: 'fixed inset-0 z-20 flex items-end sm:items-center justify-center p-2',
            },
            [backdrop, modal]
        )

        const focusTrap = createFocusTrap(el, {
            clickOutsideDeactivates: true,
        })

        const removeFromDom = () => {
            const handleTransitionEnd = () => {
                focusTrap.deactivate()
                enableBodyScroll(el)
                el.parentNode.removeChild(el)

                modal.removeEventListener(
                    'transitionend',
                    handleTransitionEnd,
                    false
                )
            }

            modal.addEventListener('transitionend', handleTransitionEnd, false)
            backdrop.classList.add('opacity-0')
            modal.classList.add('opacity-0', 'translate-y-8')
        }

        const handleKeyUp = (event) => {
            if (event.keyCode === 27) {
                handleDecline()
            }
        }

        const handleAccept = () => {
            removeFromDom()
            resolve()
        }

        const handleDecline = () => {
            window.removeEventListener('keyup', handleKeyUp, false)
            removeFromDom()
            reject()
        }

        backdrop.addEventListener('click', handleDecline, false)
        declineButton.addEventListener('click', handleDecline, false)
        acceptButton.addEventListener('click', handleAccept, false)
        window.addEventListener('keyup', handleKeyUp, false)
        window.document.body.appendChild(el)
        focusTrap.activate()
        disableBodyScroll(el, { reserveScrollBarGap: true })
        setTimeout(() => {
            backdrop.classList.remove('opacity-0')
            modal.classList.remove('opacity-0', 'translate-y-8')
        })
    })
}
