export default (autoPlay = false) => ({
    isPlaying: autoPlay,
    isEnded: false,

    toggle() {
        const video = this.$refs.video

        if (!video.paused) {
            video.pause()
        } else {
            video.play()
        }
    },

    init() {
        const video = this.$refs.video

        video.addEventListener('play', () => (this.isPlaying = true))
        video.addEventListener('pause', () => (this.isPlaying = false))
        this.$watch('isPlaying', () => (this.isEnded = video.ended))
    },
})
